
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";

const base = namespace("base");
const coupon = namespace("coupon");
const campaign = namespace("campaign");
@Component({})
export default class FilterCustomer extends Vue {
  @base.Action getDictionaryList;
  @base.Action getvehicleTree;
  @coupon.State dialog;
  @coupon.Mutation setDialog;
  @coupon.Action queryCustomers;
  @coupon.Action queryBrandList;
  @campaign.Action queryCustomerListByCollect;
  @campaign.Action queryCustomerListByLottery;
  @campaign.Action queryCustomerListByFission;
  dayjs = dayjs;
  loading = false;
  isIndeterminate = false;
  @Watch("active")
  activeChange() {
    this.firstTime = [];
  }
  @Prop({ default: "" })
  activityCode: string;
  @Prop({ default: "coupon" }) // coupon collect lottery
  queryType: string;
  //计算属性
  //   dialogVisible = false;
  fourBox = [
    { name: t("v210831.by-new-customer") },
    { name: t("v210831.by-car-brand") },
    { name: t("v210831.customer-whose-package") },
    { name: t("v210831.about-to-lose") },
    { name: t("v210831.issued-by-designated") }
  ];
  active = 0;
  emptyToLine = emptyToLine;
  optionsBrand = [];
  tableData = [];
  pickerOptions = {
    shortcuts: [
      {
        text: t("v210831.last-week"),
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: t("v210831.last-month"),
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: t("v210831.last-three-months"),
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit("pick", [start, end]);
        }
      }
    ]
  };
  pickerOptionsFuture = {
    shortcuts: [
      {
        text: t("v210831.next-week"),
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
          picker.$emit("pick", [end, start]);
        }
      },
      {
        text: t("v210831.next-month"),
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
          picker.$emit("pick", [end, start]);
        }
      },
      {
        text: t("v210831.the-next-three"),
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
          picker.$emit("pick", [end, start]);
        }
      }
    ]
  };
  firstTime = [];
  multipleSelectionArr = [];
  text = "123";
  value1 = [];
  queryForm = {
    activityCode: "", //优惠券活动编码.
    campaignCode: "", // 积客和抽奖code
    brandList: [], //品牌信息列表(string )
    consumptionTimesFrom: undefined, //消费次数From
    consumptionTimesTo: undefined, // 消费次数to
    customerList: [], //客户信息列表(object )
    filterReceivedCustomer: 1, //过滤已经发放该券的用户(1-过滤, 0-不过滤, 默认1)
    filterType: 1, //过滤方式(1-按新增客户, 2-按汽车品牌, 3-套餐即将到期客户, 4-即将流失客户)
    firstConsumptionDateFrom: undefined, //首次(到店)消费日期(From)yyyyMMdd
    firstConsumptionDateTo: undefined, //首次(到店)消费日期(To)
    lastConsumptionDateFrom: undefined, //上次(到店)消费日期(From)
    lastConsumptionDateTo: undefined, //上次(到店)消费日期(To)
    setcardExpiredDateFrom: undefined,
    setcardExpiredDateTo: undefined,
    setcardResidualTimesFrom: undefined,
    setcardResidualTimesTo: undefined,
    searchKey: ""
  };
  chechedAll = false;
  checkedCities = [];
  checked = true;
  pageNum = 1;
  created() {
    this.init();
    this.loadList();
  }
  mounted() {}
  async init() {
    this.queryForm.activityCode = this.activityCode || this.$route.params.activityCode;
    let res = await this.getvehicleTree();
    this.optionsBrand = res.data.list;
  }
  loadList() {
    this.queryForm.filterReceivedCustomer = Number(this.checked);

    this.queryForm.firstConsumptionDateFrom = this.firstTime.length
      ? dayjs(this.firstTime[0]).format("YYYYMMDD")
      : null;
    this.queryForm.firstConsumptionDateTo = this.firstTime.length ? dayjs(this.firstTime[1]).format("YYYYMMDD") : null;

    this.queryForm.lastConsumptionDateFrom = this.firstTime.length ? dayjs(this.firstTime[0]).format("YYYYMMDD") : null;
    this.queryForm.lastConsumptionDateTo = this.firstTime.length ? dayjs(this.firstTime[1]).format("YYYYMMDD") : null;

    this.queryForm.setcardExpiredDateFrom = this.firstTime.length ? dayjs(this.firstTime[0]).format("YYYYMMDD") : null;
    this.queryForm.setcardExpiredDateTo = this.firstTime.length ? dayjs(this.firstTime[1]).format("YYYYMMDD") : null;
    if (this.active === 2) {
      this.queryForm.setcardResidualTimesFrom = this.queryForm.consumptionTimesFrom;
      this.queryForm.setcardResidualTimesTo = this.queryForm.consumptionTimesTo;
    }
    let queryFn;
    if (this.queryType === "coupon") {
      queryFn = this.queryCustomers;
    } else if (this.queryType === "collect") {
      queryFn = this.queryCustomerListByCollect;
      this.queryForm.campaignCode = this.activityCode;
    } else if (this.queryType === "lottery") {
      queryFn = this.queryCustomerListByLottery;
      this.queryForm.campaignCode = this.activityCode;
    } else if (this.queryType === "fission") {
      queryFn = this.queryCustomerListByFission;
      this.queryForm.campaignCode = this.activityCode;
    }
    queryFn({
      ...this.queryForm,
      pageNum: 1,
      pageSize: 1000
    }).then(res => {
      this.tableData = res.data.list;
      this.loading = false;
    });
  }
  handleClose() {
    this.setDialog(false);
  }
  clickActive(index) {
    this.active = index;
    this.queryForm.filterType = index + 1;
    // this.loadList();
    this.queryReset();
  }
  handleSelectionChange(selection) {
    this.multipleSelectionArr = selection;
    this.chechedAll = selection.length ? true : false;
    let checkedCount = selection.length;
    this.chechedAll = checkedCount === this.tableData.length;
    this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length;
  }
  loadFunction() {
    this.chechedAll = false;
    this.loadList();
  }
  queryBut() {
    this.loadList();
  }
  checkChange(val) {
    let tableRef = this.$refs.multipleTable as any;
    tableRef.toggleAllSelection();
    this.checkedCities = val ? this.tableData : [];
    this.isIndeterminate = false;
  }
  selectAll(selection) {
    this.chechedAll = selection.length ? true : false;
  }
  iAmSure() {
    // multipleSelectionArr
    this.$emit("getSelectList", this.multipleSelectionArr);
    this.setDialog(false);
  }
  queryReset() {
    this.firstTime = [];
    this.checked = true;
    this.queryForm.consumptionTimesFrom = ""; //消费次数From
    this.queryForm.consumptionTimesTo = ""; // 消费次数to
    this.queryForm.brandList = [];
    this.queryForm.searchKey = "";
    this.loadList();
  }
  @Watch("activityCode")
  watchActivityCodeFn(val) {
    this.queryForm.activityCode = val;
  }
  // //////
}
